<template>
  <div class="sign-up">
    <div class="grid-content flex justify-center flex-col">
      <Stepper :hideHeader="selectZoneMode" :currentStep="step" :steps="steps">
        <template v-slot:stepperContent>
          <div v-show="step === zoneStepId">
            <ChooseZone :alreadySelectedZone="signUpForm.zone" :canGoToNextStep="canGoToSignUpPersonalInfo"
              :onlyActiveZone="true" @zoneClick="onZoneClicked" @next="zoneSelected"
            />
          </div>
          <div v-show="step === signUpPersonalStepId">
            <SignUpPersonalInfo :rules="rules" :errorHandler="recordError" :canGoToPreviousStep="!forcedZoneByTunnel && signUpPersonalStepId > 0"
              @back="goToZoneSelection" @next="personalInfoSelected" />
          </div>
          <div v-show="step === signUpEmailStepId">
            <SignUpEmail :signUpForm="signUpForm" :errorHandler="recordError" :canGoToPreviousStep="canGoToSignUpPersonalInfo && signUpEmailStepId > 0"
              @back="goToSignUpPersonalInfo" @next="emailInfoSelected">
              <template v-if='selectedZone'>
                <SignUpZoneConfirmations
                  :zone="selectedZone"
                  :rules="rules"
                  :errorHandler="recordError"
                  @on-software-notifications-changed="signUpForm.softwareNotifications = $event"
                  @on-commercial-notifications-changed="signUpForm.commercialNotifications = $event"
                />
              </template>
            </SignUpEmail>
          </div>
        </template>
      </Stepper>
      <div v-if="!selectZoneMode" class="flex justify-center">
        <OrSignIn />
      </div>
    </div>
    <ModalMobileAppBetter :visibility="isMobileAppBetterModalOpen" @on-cancel="continueWithAuth()" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import SignUpPersonalInfo from './SignUpPersonalInfo.vue';
import SignUpEmail from './SignUpEmail.vue';
import ChooseZone from '@/modules/landing/components/ChooseZone.vue';
import SignUpZoneConfirmations from './SignUpZoneConfirmations.vue';
import OrSignIn from './OrSignIn.vue';
import ModalMobileAppBetter from './ModalMobileAppBetter.vue';
import Stepper from '@/modules/common/generic/Stepper.vue';
import AuthControl from '../mixins/authControl.js';

export default {
  name: 'SignUp',
  components: {
    SignUpPersonalInfo,
    SignUpEmail,
    ChooseZone,
    OrSignIn,
    ModalMobileAppBetter,
    Stepper,
    SignUpZoneConfirmations
  },
  mixins: [AuthControl],
  created () {
    this.buildSteps();
  },
  mounted () {
    window.scroll(0, 0);
  },
  data () {
    return {
      step: 0,
      signUpForm: {
        zone: this.selectedZone,
        name: '',
        surname: '',
        email: '',
        password: '',
        repeatPassword: '',
        birthdate: null,
        softwareNotifications: false,
        commercialNotifications: false,
        tosAccepted: false
      },
      steps: [],
      isMobileAppBetterModalOpen: false,
      rules: {
        name: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('authenticate.pleaseInputName'),
            validator: this.checkFieldAndRecheckPassword
          },
          {
            required: true,
            trigger: 'blur',
            message: this.$t('authenticate.pleaseInputName'),
            validator: this.checkFieldAndRecheckPassword
          }
        ],
        surname: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('authenticate.pleaseInputSurname'),
            validator: this.checkFieldAndRecheckPassword
          },
          {
            required: true,
            trigger: 'blur',
            message: this.$t('authenticate.pleaseInputSurname'),
            validator: this.checkFieldAndRecheckPassword
          }
        ],
        email: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('authenticate.pleaseInputEmail'),
            validator: this.checkFieldAndRecheckPassword
          },
          {
            required: true,
            trigger: 'blur',
            message: this.$t('authenticate.pleaseInputEmail'),
            validator: this.checkFieldAndRecheckPassword
          }
        ],
        birthdate: [
          {
            trigger: 'change',
            message: this.$t('authenticate.pleaseInputBirthdate'),
            validator: this.checkFieldAndRecheckPassword
          },
          {
            trigger: 'blur',
            message: this.$t('authenticate.pleaseInputBirthdate'),
            validator: this.checkFieldAndRecheckPassword
          }
        ]
      }
    };
  },
  computed: {
    ...mapState('user', ['deciplusToken']),
    ...mapState('domain', ['zones']),
    ...mapGetters('domain', ['isMultiSite', 'getZonesVisibleOnline']),
    ...mapGetters('config', ['facebookTrackingId', 'selectedZone', 'forcedZoneByTunnel']),
    canGoToSignUpPersonalInfo () {
      return this.signUpForm?.zone?.id != null;
    },
    canGoToSignUpEmail () {
      return this.canGoToSignUpPersonalInfo && this.signUpForm.name && this.signUpForm.surname && this.signUpForm.birthdate;
    },
    shouldExecuteLinkAccounts () {
      return this.$router.currentRoute.value.query.todo === 'linkAccounts' && this.deciplusToken;
    },
    zoneStepId () {
      return this.steps.find(step => step.name === 'zone')?.stepId;
    },
    signUpPersonalStepId () {
      return this.steps.find(step => step.name === 'signUpPersonal')?.stepId;
    },
    signUpEmailStepId () {
      return this.steps.find(step => step.name === 'singUpEmail')?.stepId;
    },
    selectZoneMode () {
      return this.$route.query.redirectToShop === 'true';
    }
  },
  methods: {
    ...mapActions('user', ['loadDomainUserInfo', 'signUpForDomain']),
    ...mapActions('config', ['updateTunnelConfigurationShopAndCalendarByZone']),
    ...mapMutations('config', ['SET_SELECTED_ZONE']),
    ...mapMutations('user', ['SET_DECIPLUS_TOKEN']),
    buildSteps () {
      let step = 0;
      step = this.addZoneStep(step);
      step = this.addSignUpPersonalStep(step);
      step = this.addSignUpEmailStep(step);
      return step;
    },
    autoAssignZone (zone) {
      this.onZoneClicked(zone);
    },
    addZoneStep (step) {
      if (this.forcedZoneByTunnel) {
        const zone = this.zones.find(zone =>
          zone.id === this.forcedZoneByTunnel
        );
        this.autoAssignZone(zone);
      } else if (this.isMultiSite) {
        if (this.getZonesVisibleOnline?.length === 1) {
          this.SET_SELECTED_ZONE(this.getZonesVisibleOnline[0]);
          this.signUpForm.zone = this.getZonesVisibleOnline[0];
        } else {
          const stepZone = {
            name: 'zone',
            stepId: step++,
            openStep: this.goToZoneSelection
          };
          this.steps.push(stepZone);
        }
      } else {
        this.autoAssignZone(this.zones[0]);
      }
      return step;
    },
    addSignUpPersonalStep (step) {
      const stepSingUpPersonal = {
        name: 'signUpPersonal',
        stepId: step++,
        openStep: this.goToSignUpPersonalInfo
      };
      this.steps.push(stepSingUpPersonal);
      return step;
    },
    addSignUpEmailStep (step) {
      const stepSingUpEmail = {
        name: 'singUpEmail',
        stepId: step++,
        openStep: this.goToSignUpEmail
      };
      this.steps.push(stepSingUpEmail);
      return step;
    },
    onZoneClicked (selection) {
      if (this.$route.query.redirectToShop) {
        this.updateTunnelConfigurationShopAndCalendarByZone(selection.id);
        this.$router.push({ name: 'shop' });
      } else {
        this.signUpForm.zone = selection;
      }
      this.SET_SELECTED_ZONE(selection);
    },
    zoneSelected (selection) {
      this.goToSignUpPersonalInfo();
    },
    personalInfoSelected (selection) {
      this.signUpForm = { ...this.signUpForm, ...selection };
      this.goToSignUpEmail();
    },
    emailInfoSelected (selection) {
      this.signUpForm = { ...this.signUpForm, ...selection };
      this.onSignUp();
    },
    goToZoneSelection () {
      this.step = this.zoneStepId;
    },
    goToSignUpPersonalInfo () {
      if (this.canGoToSignUpPersonalInfo) {
        this.step = this.signUpPersonalStepId;
      }
    },
    goToSignUpEmail () {
      if (this.canGoToSignUpEmail) {
        this.step = this.signUpEmailStepId;
      }
    },
    async onSignUp () {
      try {
        await this.signUp();
        if (this.facebookTrackingId) {
          this.$information.track.registrationWithFacebook();
        }
        this.$information.track.registrationWithGoogle();
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          switch (err.response.data.message) {
            case 'deciplus-member-add-club-email-already-registered':
              this.recordError(err, this.$t('authenticate.emailAlreadyTakenUseForgottenPassword'));
              break;
            case 'member-already-exists':
              this.recordError(err, this.$t('authenticate.memberAlreadyExists'));
              break;
            default:
              this.recordError(err, this.$t('authenticate.pleaseLoginOrCheckCredentials'));
              break;
          }
        } else {
          this.recordError(err, this.$t('authenticate.pleaseLoginOrCheckCredentials'));
        }
      }
    },
    async signUp () {
      const previousDeciplusToken = this.deciplusToken;
      await this.preAuth();
      if (previousDeciplusToken) {
        this.SET_DECIPLUS_TOKEN(previousDeciplusToken);
      }
      await this.signUpForDomain({ ...this.signUpForm, zone: this.signUpForm.zone.id });

      await this.loadDomainUserInfo();
      this.isMobileAppBetterModalOpen = true;
      if (this.shouldExecuteLinkAccounts) {
        this.linkAccounts();
      }
    },
    async linkAccounts () {
      await this.$services.HTTP.user.linkClubAccountToGlobalAccount(this.signUpForm);
    },
    continueWithAuth () {
      this.isMobileAppBetterModalOpen = false;
      this.postAuth();
    },
    recordError (error, message, duration = 3000) {
      this.$information.capture({ error });
      this.$information.alert({
        message,
        duration
      });
    },
    checkFieldAndRecheckPassword (rule, value, callback) {
      if (!value) {
        callback(new Error(rule.message));
      } else {
        if (this.signUpForm.password !== '') {
          this.$refs.emailInfoForm.validateField('password');
        }
        callback();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.el-steps{
  margin-bottom: 25px;
}
</style>
